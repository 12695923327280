<template>
  <div class="ant-card" id="course_category">
    <a-spin v-if="isShow" :spinning="spinning" size="large">
      <div class="ant-card-body">
        <div class="head-box">
          <div class="table-operator">
            <span class="item">
              商品名称：
              <a-input v-model="filter.name" placeholder="商品名称关键词" style="width: 210px;"/>
            </span>
            <span class="item">
              售卖方式：
              <a-select v-model="filter.saleType" placeholder="售卖方式" style="width: 120px;" @change="searchClick">
                <a-select-option :value="0">全部</a-select-option>
                <a-select-option :value="1">现金</a-select-option>
                <a-select-option :value="2">积分</a-select-option>
                <a-select-option :value="3">现金&积分</a-select-option>
              </a-select>
            </span>
            <span class="item">
              库存状态：
              <a-select v-model="filter.isStock" placeholder="库存状态" style="width: 120px;" @change="searchClick">
                <a-select-option :value="0">全部</a-select-option>
                <a-select-option :value="1">正常</a-select-option>
                <a-select-option :value="2">异常</a-select-option>
              </a-select>
            </span>
            <span class="item">
              商品状态：
              <a-select v-model="filter.state" placeholder="是否上架" style="width: 120px;" @change="searchClick">
                <a-select-option :value="0">全部</a-select-option>
                <a-select-option :value="1">上架</a-select-option>
                <a-select-option :value="2">下架</a-select-option>
              </a-select>
            </span>
            <span class="item">
              <a-button class="margin_right10" type="primary" @click="searchClick">搜索</a-button>
              <a-button class="margin_right10" type="primary" @click="resetClick">重置</a-button>
              <router-link to="/physicalCard/physicalGoods/add/0">
                <a-button class="margin_right10" type="primary">添加商品</a-button>
              </router-link>
            </span>
          </div>
        </div>
        <div class="contant-box">
          <a-table
            ref="table" 
            :columns="columns"
            :data-source="tableList"
            @expand="expandClick"
            :row-key="record => record.id"
            :expandedRowKeys="expandedRowKeys"
            :loading="tableLoading"
            :pagination="false"
          >
            <span slot="types" slot-scope="text, record">
              {{ record.categoryNameOne }} -- {{ record.categoryNameTwo }}
            </span>
            <span slot="saleType" slot-scope="text, record">
              {{ saleTypeMap.get(+record.saleType)}}
            </span>
            <span slot="stock" slot-scope="text, record">
              {{+record.stock >= 30 ? '正常' : '异常'}} -- {{ record.stock }}
            </span>
            <span slot="state" slot-scope="text, record">
              {{ +record.state === 1 ? '已上架' : '已下架'}}
            </span>
            <span slot="action" slot-scope="text, record">
              <router-link :to="`/physicalCard/physicalGoods/add/${record.id}`">
                编辑
              </router-link>
              <a-popconfirm
                v-if="operation.sale"
                style="margin-left: 10px"
                :title="record.state === 1 ? popTitleDown : popTitleUp"
                @confirm="isSaleClick(record)"
              >
                <a>{{ +record.state === 1 ? '下架' : '上架'}}</a>
              </a-popconfirm>
            </span>
            <div slot="expandedRowRender" class="sale-detail" slot-scope="record">
              <div class="head-img">
                <img v-for="(item, index) in headImg" :key="index" :src="item">
              </div>
              <div class="chart">
                <div class="chartTitle">最近一周订单量</div>
                <div ref="EChartLineRef" class="chart"></div>
              </div>
            </div>
          </a-table>
        </div>
        <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
      </div>
    </a-spin>
    <router-view v-else />
  </div>
</template>

<script>
import * as echarts from 'echarts'
import MyPagination from "@/components/pagination/MyPagination";
import { getGoodsListAPI, removedGoodsAPI, getGoodsOrderInfoAPI } from '@/request/api/physicalCard'
import { GoodsColumns } from '../constants'
import { codeFn } from '@/utils/tools'
const saleTypeMap = new Map([
  [1, '现金'],
  [2, '积分'],
  [3, '现金&积分']
])
export default {
  components: { MyPagination },
  computed: {
    isShow() {
      return this.$route.meta.isShow
    }
  },
  watch: {
    isShow(val) {
      this.expandedRowKeys = this.$options.data().expandedRowKeys
      if (val) this.fetchData()
    }
  },
  data() {
    return {
      saleTypeMap,
      operation: {
        add: false,
        edit: false,
        sale: false
      },
      filter: {
        name: '',
        state: 0,
        saleType: 0,
        isStock: 0
      },
      spinning: false, // 全局loading
      tableLoading: false,
      expandedRowKeys:[], // 展开的行
      columns: GoodsColumns,
      tableList: [],
      rules: {
        isLocking: [{ required: true, message: "请选择账号状态", trigger: "change" }],
      },
      pageNo: 1, // 当前页
      pageSize: 20, // 每页条数
      count: 0, // 列表的总条数
      popTitleDown: '下架的商品，将无法被用户搜索和访问到，是否下架此商品',
      popTitleUp: '上架后的商品，用户就可以下单了，是否上架此商品',
      headImg: []
    };
  },
  created() {
    this.operation.add = codeFn('addGoods')
    this.operation.edit = codeFn('editGoods')
    this.operation.sale = codeFn('saleGoods')
    this.fetchData()
  },
  methods: {
    // 是否下架
    async isSaleClick(record) {
      const state = record.state === 1 ? 2 : 1
      const params = {
        id: record.id,
        state: state
      }
      const { code } = await removedGoodsAPI(params)
      if (code !== 200) return
      this.$message.success(`${state === 2 ? '下架' : '上架'}成功`)
      this.fetchData()
    },
    // 搜索
    searchClick() {
      this.pageNo = 1
      this.expandedRowKeys = []
      this.fetchData()
    },

    // 点击重置
    resetClick(){
      this.filter = this.$options.data().filter
      this.fetchData()
    },
    // 点击展开图标
    expandClick(expanded,record) {
      if(expanded){
        this.expandedRowKeys = [record.id]
        this.getDetail(record.id)
      } else {
        this.expandedRowKeys = []
      }
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.expandedRowKeys = []
      this.fetchData();
    },
    // 列表数据
    async fetchData() {
      this.tableLoading = true
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        ...this.filter
      }
      const { code, data } = await getGoodsListAPI(params)
      this.tableLoading = false
      if (code !== 200) return
      this.tableList = data.data
      this.count = data.count
    },
    async getDetail(id) {
      this.headImg = []
      const { code, data } = await getGoodsOrderInfoAPI({id: id})
      if (code !== 200) return
      this.headImg = data.headImg
      const orderCount = data.orderCount
      const date = [], num = []
      for (const key in orderCount) {
        date.push(key)
        num.push(orderCount[key])
      }
      this.drawLine(date, num)
    },
    drawLine(date, num) {
      const option = {
        xAxis: {
          type: 'category',
          data: date
        },
         grid: {
          left: '3%',
          right: '4%',
          bottom: '6%',
          top: '4%',
          containLabel: true
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [
          {
            data: num,
            type: 'line',
            smooth: true
          }
        ]
      };
      const myChart = echarts.init(this.$refs.EChartLineRef)
      option && myChart.setOption(option)
    }
  }
};
</script>

<style lang="less" scoped>
.item{
  margin-right: 20px;
  margin-bottom: 10px;
}
.head-box {
  display: flex;
  margin-bottom: 30px;
}
.btn-edit {
  margin-right: 10px;
}
.table-operator{
  display: flex;
  flex-wrap: wrap;
}
/deep/.vue-treeselect__control{
  width: 120px;
}
/deep/ .ant-input{
  min-height: 36px !important;
}
/deep/ .ant-select-selection--single{
  min-height: 36px !important;
}
/deep/.ant-select-selection__rendered{
  line-height: 35px;
}
.head-img {
  display: flex;
  max-width: 50%;
  img {
    max-width: 150px;
    max-height: 150px;
    margin-right: 20px;
  }
}
.sale-detail {
  display: flex;
  width: 100%;
  .chart {
    height: 100%;
    width: 70%;
    height: 150px;
  }
}
</style>
